<template>
  <Page title="">
    <v-row>
      <v-col cols="12">
        <Panel title="Regional View">
          <div class="ma-3 pb-2">
            <p>
              <ul>
                <li>The Regional View tab aims to answer the question: "for my region, which products are most licensed and by which customers?"</li>
                <li>The content on this tab is created in PowerBI.</li>
                <li>This tab is gated by MS Sales permissions</li>
                <li>Data can be exported using standard PBI 'export' features</li>
              </ul>
            </p>
          </div>
        </Panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col align-self="center">
        <iframe width="100%" height="800" src="https://msit.powerbi.com/reportEmbed?reportId=6ae5db75-0a76-4f17-aa32-a3c308ad09ca&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D" frameborder="0" allowFullScreen="true"></iframe>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
export default {
  components: {
  }
}
</script>
